import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom'; 
import './App.css';
import Orders from './Orders'; // Import Orders page
import Header from './Header'; // Import the Header component
import Footer from './Footer'; // Import the Footer component

function App() {
  // Define product placeholders
  const [products] = useState([
    { name: "Product 1", price: "R1 500", status: "Coming Soon" },
    { name: "Product 2", price: "R1 300", status: "Coming Soon" },
    { name: "Product 3", price: "R1 800", status: "Coming Soon" },
    { name: "Product 4", price: "R2 300", status: "Coming Soon" },
    { name: "Product 5", price: "R1 600", status: "Coming Soon" },
    { name: "Product 6", price: "R2 600", status: "Coming Soon" },
    { name: "Product 7", price: "R300", status: "Coming Soon" },
    { name: "Product 8", price: "R400", status: "Coming Soon" },
  ]);

  const [orders, setOrders] = useState([]); // To store the added items
  const navigate = useNavigate(); // Hook to navigate between pages

  // Function to handle adding products to the orders list
  const addToOrder = (product) => {
    const confirmed = window.confirm(`Are you sure you want to add ${product.name} to your orders?`);
    if (confirmed) {
      setOrders([...orders, product]);
    }
  };

  // Function to handle clicking "Order Now" and navigating to the Orders page
  const handleOrderNow = () => {
    navigate('/orders');
  };

  return (
    <div className="App">
      <Header />

      <main className="App-main">
        <section className="promo-section">
          <h1>Get your Graduation Set Now!</h1>
          <p>Complete your special day with our premium graduation gowns and accessories.</p>

          <div className="product-grid">
            {products.map((product, index) => (
              <div key={index} className="product-placeholder" onClick={() => addToOrder(product)}>
                <div className="product-info">
                  <p className="product-status">{product.status}</p>
                  <p className="product-price">{product.price}</p>
                </div>
              </div>
            ))}
          </div>

          <button className="cta-button" onClick={handleOrderNow}>Order Now</button>
        </section>
      </main>

      <Footer />
    </div>
  );
}

function MainApp() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/orders" element={<Orders orders={[]} />} />
      </Routes>
    </Router>
  );
}

export default MainApp;
