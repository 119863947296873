import React from 'react';

function Footer() {
  return (
    <footer className="App-footer">
      <p>&copy; Elite Gowns. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
