import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './grad.png';  // Adjust the path to your logo if needed

function Header() {
  const navigate = useNavigate(); // For navigation to other pages

  const handleOrderNow = () => {
    navigate('/orders');
  };

  return (
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <nav className="App-nav">
        <a href="#" onClick={handleOrderNow}>Order Now</a>
        <a href="#" onClick={() => navigate('/orders')}>View Your Orders</a>
      </nav>
    </header>
  );
}

export default Header;
