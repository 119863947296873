// Orders.js
import React from 'react';
import Header from './Header';
import Footer from './Footer';

function Orders({ orders }) {
  return (
    <div>
      <Header />  {/* Use Header here */}
      <main>
        <h2>Your Orders</h2>
        {/* Display orders */}
      </main>
      <Footer />  {/* Use Footer here */}
    </div>
  );
}

export default Orders;
